import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://billing.bigbilltech.com/',
  withCredentials: true,
});

instance.interceptors.request.use((config) => {
  const csrftoken = document.cookie.match(/csrftoken=([^;]+)/)?.[1];
  if (csrftoken) {
    config.headers['X-CSRFToken'] = csrftoken;
  }
  return config;
});

export default instance;
