import axios from 'axios';

// Function to get CSRF token from cookies
const getCSRFToken = () => {
  const name = 'csrftoken';
  const cookies = document.cookie.split('; ');
  const csrfCookie = cookies.find((cookie) => cookie.startsWith(`${name}=`));
  return csrfCookie ? csrfCookie.split('=')[1] : null;
};

// Utility function to log missing CSRF token
const logMissingCSRF = () => {
  console.warn(
    'CSRF token not found in cookies. Ensure the server sets the csrftoken cookie during the initial request.'
  );
};

// Create an Axios instance
const instance = axios.create({
  baseURL: 'https://billing.bigbilltech.com/', // Update with your API base URL
  withCredentials: true, // Include cookies with requests
});

// Add an interceptor to include the CSRF token in all requests
instance.interceptors.request.use(
  (config) => {
    const csrfToken = getCSRFToken();
    if (csrfToken) {
      config.headers['X-CSRFToken'] = csrfToken; // Add CSRF token to headers
    } else {
      logMissingCSRF();
    }
    return config;
  },
  (error) => {
    // Handle errors during request setup
    console.error('Error during request configuration:', error);
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle server errors
instance.interceptors.response.use(
  (response) => {
    // Pass successful responses directly
    return response;
  },
  (error) => {
    // Handle server errors
    if (error.response) {
      const { status, data } = error.response;
      console.error(`API Error (${status}):`, data);
    } else if (error.request) {
      console.error('No response received:', error.request);
    } else {
      console.error('Request configuration error:', error.message);
    }
    return Promise.reject(error);
  }
);

// Function to ensure CSRF token is initialized
export const ensureCSRFToken = async () => {
  try {
    await instance.get('/set-csrf/'); // Endpoint to set the CSRF cookie
    console.log('CSRF token initialized successfully');
  } catch (error) {
    console.error('Failed to initialize CSRF token:', error);
  }
};

export default instance;
