import axios from 'axios';

// Function to get CSRF token from cookies
const getCSRFToken = () => {
  const name = 'csrftoken';
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
};

// Create Axios instance
const instance = axios.create({
  baseURL: 'https://billing.bigbilltech.com/',
  withCredentials: true, // Include cookies with requests
});

// Add interceptor to include CSRF token in all requests
instance.interceptors.request.use((config) => {
  const csrfToken = getCSRFToken();
  if (csrfToken) {
    config.headers['X-CSRFToken'] = csrfToken;
  }
  return config;
});

export default instance;
