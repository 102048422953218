import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../components/Button';
import LoadingSpinner from '../components/LoadingSpinner'; 
import logo from '../components/logo.PNG'; 
import '../styles/LoginPage.css';
import { useAuth } from '../context/AuthContext';

const LoginPage = () => {
  const { login } = useAuth();  // Get the login function from context
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage('');  // Clear previous error message

    try {
      // Send the login request to the backend using context's login function
      await login(username, password);  // This will handle the authentication and session management

      // After a successful login, navigate to home
      navigate('/home');
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-page">
      <div className="header">
        <div className="logo-container">
          <img src={logo} alt="Bigbill AR Logo" className="logo" />
          <span className="logo-text">Bigbill AR Portal</span>
        </div>
      </div>
      <div className="login-container">
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div className="login-form">
            <h2>Login</h2>
            <form onSubmit={handleLogin}>
              <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="login-form-element"
                required
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="login-form-element"
                required
              />
              {errorMessage && <p className="error-message">{errorMessage}</p>}
              <Button type="submit" className="login-form-element" gradient disabled={loading}>
                {loading ? 'Logging in...' : 'Login'}
              </Button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginPage;
