import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import axios from '../utils/axios';
import '../styles/LoginPage.css';
import logo from '../components/logo.png';
import Button from '../components/Button';

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoginError('');

    try {
      const response = await axios.post('/api/login/', {
        username,
        password,
      });

      if (response.data.status === 'success') {
        const { userType, providerName, firstName, lastName } = response.data;
        login({ userType, providerName, firstName, lastName });
        navigate('/home');
      } else {
        setLoginError('Invalid username or password');
      }
    } catch (error) {
      setLoginError(error.response?.data?.message || 'Login failed. Please try again.');
    }
  };

  return (
    <div className="login-page">
      <div className="login-header">
        <img src={logo} alt="Bigbill Logo" className="logo" />
        <h1>Bigbill Portal</h1>
      </div>
      <div className="login-container">
        {loginError && <p className="error-message">{loginError}</p>}
        <form onSubmit={handleLogin}>
          <label htmlFor="username">Username</label>
          <input
            id="username"
            type="text"
            placeholder="Enter your username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <label htmlFor="password">Password</label>
          <input
            id="password"
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <Button type="submit" gradient>
            Login
          </Button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
